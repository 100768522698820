import React from 'react'
import { H2 } from 'components/typography'
import MediaCard from 'components/cards/highlight/MediaCard'
import HighlightCard from 'components/cards/highlight/HighlightCard'
import Carousel from './Carousel'
import styles from './TikTokCarousel.module.css'

const TikTokCarousel = ({ tiktoks = [] }) => {
  return (
    <div className={styles.container}>
      <H2>Videos de TikTok 🪩</H2>
      <Carousel className={styles.carousel}>
        {tiktoks.map(({ title, background, category, link, detail }, i) => (
          <div className={styles.slide} key={i}>
            <MediaCard
              index={i}
              imgUrl={background}
              title={title}
              href={link}
              category={category}
              detail={detail}
              backgroundPosition='top'
            />
          </div>
        ))}
        <div className={styles.slide} key='last'>
          <HighlightCard
            title='Descubre más en nuestras redes sociales'
            buttonProps={{
              size: 'tiny',
              type: 'accent-tertiary',
              href: 'https://www.tiktok.com/@mundoestudiante'
            }}
          />
        </div>
      </Carousel>
    </div>
  )
}

export default TikTokCarousel

//@ts-check
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import QRCode from 'qrcode.react'
import config from 'config'
import { useAuth } from 'context/AuthProvider'
import { sendImageLine } from 'api/sockets'
import CoolAndSmart from 'assets/svg/CoolAndSmart'
import ImageIcon from 'assets/icons/Image'
import ZoomIn from 'assets/icons/ZoomIn'
import Blackboard from 'assets/icons/Blackboard'
import { clear, drawLine, handleDownload } from 'utils/canvas'
import DownloadButton from 'components/buttons/Download'
import Button from 'components/buttons/Button'
import { H2, Paragraph } from 'components/typography'
import Modal from 'components/modals/Modal'
import ClassroomToolbar, { classroomToolbarIds } from './Toolbar'
import { classroomTabIds } from './Tabs'
import styles from './Photo.module.css'

/** @type {{color: string, lineWidth: number} | any} */
const stateByTool = {
  [classroomToolbarIds.draw]: { color: '#9400D3', lineWidth: 2 },
  [classroomToolbarIds.erase]: { color: 'transparent', lineWidth: 30 }
}

function ClassroomPhoto({
  socket,
  selectedTool,
  onToolChange,
  zoom,
  isActive,
  isVisible,
  teacher,
  canvasContext,
  onSaveCanvasContext,
  onClearCanvasContext,
  onZoomChange
}) {
  const { student } = useAuth()
  const [tool, setTool] = useState(stateByTool[classroomToolbarIds.draw])
  const [step, setStep] = useState(1)
  const [isDrawing, setDrawing] = useState(false)
  const [photo, setPhoto] = useState(null)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [isCanvasReady, setIsCanvasReady] = useState(false)
  const [showZoom, setShowZoom] = useState(false)

  const canvasRef = useRef(null)

  const handleSaveContext = useCallback(
    () =>
      canvasRef.current &&
      canvasRef.current.width &&
      canvasRef.current.height &&
      onSaveCanvasContext(
        [
          canvasRef.current
            .getContext('2d', { willReadFrequently: true })
            .getImageData(
              0,
              0,
              canvasRef.current.width,
              canvasRef.current.height
            )
        ],
        classroomTabIds.photo
      ),

    [onSaveCanvasContext]
  )

  const handleRestoreCanvasContext = useCallback(() => {
    canvasContext?.map(imageData =>
      canvasRef.current
        .getContext('2d', { willReadFrequently: true })
        .putImageData(imageData, 0, 0)
    )
  }, [canvasContext])

  const handleClearCanvasContext = useCallback(() => {
    onClearCanvasContext(classroomTabIds.photo)
    clear(canvasRef)
  }, [onClearCanvasContext])

  const throttle = (callback, delay) => {
    let previousCall = new Date().getTime()
    return function () {
      const time = new Date().getTime()
      if (time - previousCall >= delay) {
        previousCall = time
        callback.apply(null, arguments)
      }
    }
  }

  const handleMouseDown = e => {
    if (!isActive || !teacher || !photo) return
    const { clientY, clientX } = e
    setDrawing(true)
    const { x, y } = canvasRef.current.getBoundingClientRect()
    setPosition({ x: clientX - x, y: clientY - y })
  }
  const handleMouseMove = e => {
    if (!isDrawing || !photo) return
    const { clientY, clientX } = e
    const { width, height, x, y } = canvasRef.current.getBoundingClientRect()
    const endX = clientX - x
    const endY = clientY - y
    drawLine(
      canvasRef,
      position.x,
      position.y,
      endX,
      endY,
      tool.color,
      tool.lineWidth
    )
    sendImageLine({
      socket,
      teacher,
      x0: position.x / width,
      y0: position.y / height,
      x1: endX / width,
      y1: endY / height,
      color: tool.color,
      lineWidth: tool.lineWidth
    })
    setPosition({ x: endX, y: endY })
  }
  const handleMouseLeave = e => {
    if (!isDrawing || !photo) return
    const { clientY, clientX } = e
    const { width, height, x, y } = canvasRef.current.getBoundingClientRect()
    const endX = clientX - x
    const endY = clientY - y
    drawLine(
      canvasRef,
      position.x,
      position.y,
      endX,
      endY,
      tool.color,
      tool.lineWidth
    )
    sendImageLine({
      socket,
      teacher,
      x0: position.x / width,
      y0: position.y / height,
      x1: endX / width,
      y1: endY / height,
      color: tool.color,
      lineWidth: tool.lineWidth,
      saveContext: true
    })
    setDrawing(false)
  }
  const handleTouchDown = e => {
    if (!isActive || !photo) return
    const { clientY, clientX } = e.touches[0]
    setDrawing(true)
    const { x, y } = canvasRef.current.getBoundingClientRect()
    setPosition({ x: clientX - x, y: clientY - y })
  }
  const handleTouchMove = e => {
    if (!isDrawing || !photo) return
    const { clientY, clientX } = e.touches[0]
    const { width, height, x, y } = canvasRef.current.getBoundingClientRect()
    const endX = clientX - x
    const endY = clientY - y
    drawLine(
      canvasRef,
      position.x,
      position.y,
      endX,
      endY,
      tool.color,
      tool.lineWidth
    )
    sendImageLine({
      socket,
      teacher,
      x0: position.x / width,
      y0: position.y / height,
      x1: endX / width,
      y1: endY / height,
      color: tool.color,
      lineWidth: tool.lineWidth
    })
    setPosition({ x: endX, y: endY })
  }
  const handleTouchLeave = () => {
    if (!isDrawing || !photo) return
    setDrawing(false)
  }

  useEffect(() => {
    if (!socket || !canvasRef || !canvasRef.current) return

    socket.on(
      'classroom:teachers:image-line',
      ({ x0, y0, x1, y1, color, lineWidth, saveContext }) => {
        const { width, height } = canvasRef.current
        const startX = x0 * width
        const startY = y0 * height
        const endX = x1 * width
        const endY = y1 * height
        drawLine(canvasRef, startX, startY, endX, endY, color, lineWidth)
        saveContext && handleSaveContext()
      }
    )

    socket.on('classroom:teachers:image-clear', () => {
      clear(canvasRef)
      const imageObj = new Image()
      imageObj.crossOrigin = 'anonymous'
      imageObj.src = photo
      imageObj.onload = () => scaleToFit(imageObj)
    })

    socket.on('classroom:teachers:image-select', ({ image }) => {
      if (!isCanvasReady) return
      setPhoto(image)
      setStep(0)
      const imageObj = new Image()
      imageObj.crossOrigin = 'anonymous'
      imageObj.src = image
      imageObj.onload = () => scaleToFit(imageObj)
    })

    socket.on('classroom:teachers:image-rotation', ({ rotationDegree }) => {
      const img = new Image()
      img.crossOrigin = 'anonymous'
      img.src = photo
      img.onload = () => drawRotated(img, rotationDegree)
    })

    return () => {
      if (!socket) return
      socket.off('classroom:teachers:image-line')
      socket.off('classroom:teachers:image-clear')
    }
  }, [handleSaveContext, isCanvasReady, photo, socket])

  useLayoutEffect(() => {
    if (isCanvasReady) return
    canvasRef.current.width = canvasRef.current.getBoundingClientRect().width
    canvasRef.current.height = canvasRef.current.getBoundingClientRect().height
    handleClearCanvasContext()
    setIsCanvasReady(true)
  }, [handleClearCanvasContext, isCanvasReady])

  useLayoutEffect(() => {
    if (!isVisible) return
    let timeoutId
    const resize = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        const needResize =
          canvasRef.current.width !=
            canvasRef.current.getBoundingClientRect().width ||
          canvasRef.current.height !=
            canvasRef.current.getBoundingClientRect().height
        if (needResize) {
          canvasRef.current.width =
            canvasRef.current.getBoundingClientRect().width
          canvasRef.current.height =
            canvasRef.current.getBoundingClientRect().height
        }
        handleRestoreCanvasContext()
      }, 500)
    }

    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [handleRestoreCanvasContext, isVisible])

  useLayoutEffect(() => {
    if (isDrawing) return
    handleSaveContext()
  }, [handleSaveContext, isDrawing])

  const drawRotated = (img, degrees) => {
    const blackboard = canvasRef.current
    if (!blackboard) return
    const rotatedDimensions = degrees % 180 !== 0
    blackboard.width = blackboard.getBoundingClientRect().width
    blackboard.height = blackboard.getBoundingClientRect().height
    const ctx = blackboard.getContext('2d', { willReadFrequently: true })
    ctx.clearRect(0, 0, blackboard.width, blackboard.height)
    ctx.save()
    ctx.translate(blackboard.width / 2, blackboard.height / 2)
    ctx.rotate((degrees * Math.PI) / 180)
    const wrh = rotatedDimensions
      ? img.height / img.width
      : img.width / img.height
    let newWidth = blackboard.width
    let newHeight = newWidth / wrh
    if (newHeight > blackboard.height) {
      newHeight = blackboard.height
      newWidth = newHeight * wrh
    }
    if (rotatedDimensions)
      ctx.drawImage(
        img,
        -blackboard.height / 2,
        -blackboard.width / 2,
        newHeight,
        newWidth
      )
    else
      ctx.drawImage(
        img,
        -blackboard.width / 2,
        -blackboard.height / 2,
        newWidth,
        newHeight
      )
    ctx.restore()
  }

  const scaleToFit = img => {
    canvasRef.current.width = canvasRef.current.getBoundingClientRect().width
    canvasRef.current.height = canvasRef.current.getBoundingClientRect().height
    const blackboard = canvasRef.current
    const ctx = blackboard.getContext('2d', { willReadFrequently: true })
    const scale = Math.min(
      blackboard.width / img.width,
      blackboard.height / img.height
    )
    const x = blackboard.width / 2 - (img.width / 2) * scale
    const y = blackboard.height / 2 - (img.height / 2) * scale
    ctx.clearRect(0, 0, blackboard.width, blackboard.height)
    ctx.drawImage(img, x, y, img.width * scale, img.height * scale)
  }

  return (
    <div className={styles.blackboard} hidden={!isVisible}>
      <div className={styles.innerContainer}>
        {step === 1 && (
          <div className={styles.step1}>
            <CoolAndSmart className={styles.svg} />
            <H2>Enséñale al profe lo que has aprendido</H2>
            <Paragraph type='body1Bold'>
              Recuerda que es el profe quien compartirá la foto, súbela para que
              podáis verla juntos
            </Paragraph>
            <Button
              label='Subir desde el móvil'
              size='small'
              onClick={() => setStep(2)}
            />
            <a href={`/upload-photo/${student.id}`} target='_blank'>
              <Button label='Subir desde el ordenador' size='small' />
            </a>
          </div>
        )}
        {step === 2 && (
          <div className={styles.step2}>
            <CoolAndSmart className={styles.svg} />
            <QRCode
              value={`${config.host}/upload-photo/${student.id}`}
              size={180}
            />
            <Paragraph type='body1Bold'>
              Escanea el código QR y sube tu foto
            </Paragraph>
            <Button
              type='secondary'
              size='small'
              label='Volver'
              onClick={() => setStep(1)}
            />
          </div>
        )}
        <canvas
          ref={canvasRef}
          className={styles.canvas}
          hidden={step != 0}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseLeave}
          onMouseOut={handleMouseLeave}
          onMouseMove={throttle(handleMouseMove, 10)}
          onTouchStart={handleTouchDown}
          onTouchEnd={handleTouchLeave}
          onTouchCancel={handleTouchLeave}
          onTouchMove={throttle(handleTouchMove, 10)}
        />
      </div>
      <div className={styles.tools}>
        <ClassroomToolbar
          selectedOption={selectedTool}
          optionIdsToHide={[classroomToolbarIds.type]}
          onClick={id => {
            onToolChange(id)
            setTool(stateByTool[id])
          }}
        />
        <div className={styles.right}>
          <div className={styles.innerRight}>
            <div
              className={[styles.icons, !photo ? styles.disabled : ''].join(
                ' '
              )}
              title='Zoom'
              onClick={() => photo && setShowZoom(true)}
            >
              <ZoomIn color='var(--dark-color)' />
            </div>
            {step === 0 ? (
              <div
                className={styles.icons}
                title='Elegir foto'
                onClick={() => setStep(1)}
              >
                <ImageIcon color='var(--dark-color)' />
              </div>
            ) : (
              <div
                className={[styles.icons, !photo ? styles.disabled : ''].join(
                  ' '
                )}
                title='Ver pizarra'
                onClick={() => !!photo && setStep(0)}
              >
                <Blackboard color='var(--dark-color)' />
              </div>
            )}
          </div>
          <DownloadButton
            onClick={() => handleDownload(canvasRef)}
            disabled={canvasContext.length === 0}
          />
        </div>
      </div>
      {showZoom && (
        <ZoomImage image={photo} onClose={() => setShowZoom(false)} />
      )}
    </div>
  )
}

export default ClassroomPhoto

function ZoomImage({ image, onClose }) {
  const [zoomToScreen, setZoomToScreen] = useState(false)
  const [rotation, setRotation] = useState(0)
  const zoomCanvas = useRef(null)

  useEffect(() => {
    if (!image) return
    const img = new Image()
    img.onload = () => drawRotated(img, rotation, zoomToScreen)
    img.crossOrigin = 'anonymous'
    img.src = image
  }, [image, rotation, zoomToScreen])

  const drawRotated = (img, degrees, zoomToScreen) => {
    const pizarra = zoomCanvas.current
    if (!pizarra) return
    const rotatedDimensions = degrees % 180 !== 0
    if (zoomToScreen) {
      pizarra.width = pizarra.getBoundingClientRect().width
      pizarra.height = pizarra.getBoundingClientRect().height
    } else {
      pizarra.width = rotatedDimensions ? img.height : img.width
      pizarra.style.width = rotatedDimensions ? img.height : img.width
      pizarra.height = rotatedDimensions ? img.width : img.height
      pizarra.style.height = rotatedDimensions ? img.width : img.height
    }
    const ctx = pizarra.getContext('2d', {
      willReadFrequently: true
    })
    ctx.clearRect(0, 0, pizarra.width, pizarra.height)
    ctx.save()
    ctx.translate(pizarra.width / 2, pizarra.height / 2)
    ctx.rotate((degrees * Math.PI) / 180)
    const wrh = rotatedDimensions
      ? img.height / img.width
      : img.width / img.height
    let newWidth = pizarra.width
    let newHeight = newWidth / wrh
    if (newHeight > pizarra.height) {
      newHeight = pizarra.height
      newWidth = newHeight * wrh
    }
    if (rotatedDimensions)
      ctx.drawImage(
        img,
        -pizarra.height / 2,
        -pizarra.width / 2,
        newHeight,
        newWidth
      )
    else
      ctx.drawImage(
        img,
        -pizarra.width / 2,
        -pizarra.height / 2,
        newWidth,
        newHeight
      )
    ctx.restore()
  }

  const handleRotate = () =>
    setRotation(currentRotation => (currentRotation + 90) % 360)

  const handleClose = useCallback(() => {
    setZoomToScreen(false)
    setRotation(0)
    onClose()
  }, [onClose])
  return (
    <Modal
      okText='Girar'
      cancelText='Cerrar'
      onOk={handleRotate}
      onCancel={handleClose}
    >
      <canvas
        className={[
          styles.zoomImageAdjusted,
          zoomToScreen ? styles.canvasAdjusted : ''
        ].join(' ')}
        ref={zoomCanvas}
        onClick={() => setZoomToScreen(v => !v)}
      />
    </Modal>
  )
}

import * as React from 'react'
const Menu = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    viewBox='0 0 30 30'
    fill='none'
    {...props}
  >
    <path
      fill='#000'
      fillRule='evenodd'
      d='M7 10a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1Zm0 5a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1Zm1 4a1 1 0 1 0 0 2h14a1 1 0 1 0 0-2H8Z'
      clipRule='evenodd'
    />
    <mask
      id='menu'
      width={16}
      height={12}
      x={7}
      y={9}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'luminance'
      }}
    >
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M7 10a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1Zm0 5a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1Zm1 4a1 1 0 1 0 0 2h14a1 1 0 1 0 0-2H8Z'
        clipRule='evenodd'
      />
    </mask>
    <g mask='url(#menu)'>
      <path fill={props.color || '#fff'} d='M0 0h30v30H0z' />
    </g>
  </svg>
)
export default Menu

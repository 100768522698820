//@ts-check
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Navigate, useOutletContext } from 'react-router-dom'
import Pencil from 'assets/icons/Pencil'
import Sign from 'assets/icons/Sign'
import avatar4 from 'assets/images/robot4.png'
import { H2, Label, Paragraph } from 'components/typography'
import Button from 'components/buttons/Button'
import Tag from 'components/tags/Tag'
import DotsSpinner from 'components/spinners/DotsSpinner'
import { dateToString, getExpirationDate } from 'utils/date'
import { listPaymentMethods } from 'api/billing'
import useCustomNavigate from 'hooks/useCustomNavigate'
import { useAuth } from 'context/AuthProvider'
import config from 'config'
import styles from './Account.module.css'

function Account() {
  const [preferredCard, setPreferredCard] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const { student } = useAuth()
  const navigate = useCustomNavigate()
  // @ts-ignore
  const { redirectToPersonalData } = useOutletContext()
  const academicBackground =
    student?.academicBackground || student?.trial?.academicBackground

  useEffect(() => {
    listPaymentMethods()
      .then(({ cards, preferredCard }) => {
        const targetCard = cards.find(card => card.id === preferredCard) || {}
        setPreferredCard(targetCard)
      })
      .catch(error => {
        console.error(
          'Ha habido un problema listando los métodos de pago',
          error
        )
      })
      .finally(() => setIsLoading(false))
  }, [])
  if (redirectToPersonalData) return <Navigate to='/account/personal-data' />

  return (
    <section className={styles.section}>
      <div>
        <div className={styles.editableSection}>
          <H2>Datos personales 🤙</H2>
          <Pencil
            className={styles.pencilIcon}
            color='var(--purpletronic)'
            onClick={() => navigate('personal-data')}
          />
        </div>
        <div className={[styles.card, styles.personalData].join(' ')}>
          <div className={styles.profileImageWrapper}>
            {student.profileUrl ? (
              <img src={student.profileUrl} className={styles.profileImage} />
            ) : (
              <img src={avatar4} className={styles.defaultProfileImage} />
            )}
          </div>
          <div className={styles.rightContent}>
            <div>
              <Paragraph type='body1Bold'>{student.name}</Paragraph>
              <div className={styles.bottomLine} />
            </div>
            <div hidden={!student.dateOfBirth}>
              <Paragraph type='body2'>
                {dateToString(student.dateOfBirth)}
              </Paragraph>
              <div className={styles.bottomLine} />
            </div>
            {academicBackground && (
              <Tag className={styles.tag} label={academicBackground} />
            )}
          </div>
        </div>

        {student.dateOfBirth && !student.isAnAdult && !config.hide && (
          <>
            <div className={styles.editableSection}>
              <H2>Datos de contacto 📲</H2>
              <Pencil
                className={styles.pencilIcon}
                color='var(--purpletronic)'
                onClick={() => navigate('contact-data')}
              />
            </div>
            {student.firstTutor ? (
              <div className={[styles.card, styles.contact].join(' ')}>
                <div>
                  <Paragraph type='body1Bold'>
                    {student.firstTutor?.role}
                  </Paragraph>
                  <div className={styles.bottomLine} />
                </div>
                <div>
                  <Paragraph type='body1Bold'>
                    {student.firstTutor?.name}
                  </Paragraph>
                  <div className={styles.bottomLine} />
                </div>
                <div hidden={!student.phoneNumber?.fullPhoneNumber}>
                  <Paragraph type='body2'>
                    {student.firstTutor?.email}
                  </Paragraph>
                  <div className={styles.bottomLine} />
                </div>
                <div>
                  <Paragraph type='body2'>
                    {student.firstTutor?.phoneNumber?.fullPhoneNumber}
                  </Paragraph>
                  <div className={styles.bottomLine} />
                </div>
              </div>
            ) : (
              <div className={[styles.card, styles.contactMesssage].join(' ')}>
                <Paragraph>Aún no tienes datos de contacto</Paragraph>
              </div>
            )}
          </>
        )}

        {!config.hide && (
          <>
            <H2>Contratos 📝</H2>
            <div className={styles.documents}>
              <div className={[styles.card, styles.documentCard].join(' ')}>
                <Sign className={styles.signIcon} />
                <Label>Cláusulas de cancelación</Label>
                <Button label='Leer y firmar' size='small' />
              </div>
              <div className={[styles.card, styles.documentCard].join(' ')}>
                <Sign className={styles.signIcon} />
                <Label>Condiciones de pago</Label>
                <Link to='service-terms'>
                  <Button label='Leer y firmar' size='small' />
                </Link>
              </div>
            </div>
          </>
        )}
      </div>

      {!config.hide && (
        <div>
          <div className={styles.editableSection}>
            <H2>Métodos de pago 💳</H2>
            <Pencil
              className={styles.pencilIcon}
              color='var(--purpletronic)'
              onClick={() => navigate('payment-methods')}
            />
          </div>
          <div className={[styles.card, styles.creditCard].join(' ')}>
            {isLoading ? (
              <div className={styles.spinner}>
                <DotsSpinner />
              </div>
            ) : Object.keys(preferredCard).length > 0 ? (
              <>
                <span
                  className={[
                    styles.creditCardBrandIcon,
                    styles[`brand--${preferredCard.card.brand}`] ||
                      styles.defaultImageCard
                  ].join(' ')}
                  title={preferredCard.card.brand}
                />

                <div>
                  <Paragraph className={styles.paragraphColor} type='body1Bold'>
                    **** **** **** {preferredCard.card.last4}
                  </Paragraph>
                  <div className={styles.bottomLine} />
                </div>
                <div>
                  <Paragraph className={styles.paragraphColor} type='body1Bold'>
                    {preferredCard.billing_details.name}
                  </Paragraph>
                  <div className={styles.bottomLine} />
                </div>
                <div className={styles.bottomSection}>
                  <div>
                    <Paragraph
                      className={styles.paragraphColor}
                      type='body1Bold'
                    >
                      {getExpirationDate(
                        preferredCard.card.exp_month,
                        preferredCard.card.exp_year
                      )}
                    </Paragraph>
                    <div className={styles.bottomLine} />
                  </div>
                </div>
              </>
            ) : (
              <div className={[styles.cardMessage].join(' ')}>
                <Paragraph>No tienes ningún método de pago</Paragraph>
              </div>
            )}
          </div>

          <div>
            <div className={styles.editableSection}>
              <H2>Facturación 📄</H2>
              <Pencil
                className={styles.pencilIcon}
                color='var(--purpletronic)'
                onClick={() => navigate('billing')}
              />
            </div>
            {student.billing && Object.keys(student.billing).length > 0 ? (
              <div className={[styles.card, styles.billing].join(' ')}>
                <div>
                  <Paragraph type='body1Bold'>
                    {student.billing?.name}
                  </Paragraph>
                  <div className={styles.bottomLine} />
                </div>
                <div>
                  <Paragraph type='body1Bold'>
                    {student.billing?.email}
                  </Paragraph>
                  <div className={styles.bottomLine} />
                </div>
                <div>
                  <Paragraph type='body2'>{student.billing?.dni}</Paragraph>
                  <div className={styles.bottomLine} />
                </div>
                <div>
                  <Paragraph type='body2'>
                    {dateToString(student.billing?.dateOfBirth)}
                  </Paragraph>
                  <div className={styles.bottomLine} />
                </div>
              </div>
            ) : (
              <div className={[styles.card, styles.billingMessage].join(' ')}>
                <Paragraph>Sin datos de facturación</Paragraph>
              </div>
            )}
          </div>
        </div>
      )}
    </section>
  )
}

export default Account

import { makeQueryString } from 'utils/queries'
import { POST, GET } from './'

export const createQuestion = ({ question, imageUrl }) =>
  POST({
    url: 'students/barbeibot/question',
    data: {
      question,
      imageUrl
    }
  })

export const createClassroomQuestion = ({ question, imageUrl }) =>
  POST({
    url: 'students/barbeibot',
    data: {
      question,
      imageUrl
    }
  })

export const getTodayProgress = () =>
  GET({ url: 'students/barbeibot/today-progress' })

export const getQuestionsByDate = (dateTime = Date.now()) =>
  GET({ url: 'students/barbeibot/get-questions-by-date?date=' + dateTime })

export const findQuestionsByMonthYear = (month, year) =>
  GET({
    url:
      'students/barbeibot/find-questions-by-month-year?' +
      makeQueryString({ month, year })
  })

//@ts-check
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useLocation, matchPath } from 'react-router-dom'
import { assoc } from 'ramda'
import { getNotificationCount } from 'api/notifications'
import { AccessTypes } from 'utils/constants'
import { useAuth } from 'context/AuthProvider'
import DotsSpinner from 'components/spinners/DotsSpinner'
import config from 'config'
import styles from './LateralMenu.module.css'

const NOTIFICATIONS = '/notifications'
const ACCOUNT = '/account'
const BARBEIBOT = '/barbeibot'
const SUBSCRIPTIONS = '/subscriptions'

const Options = {
  '/': { label: 'Inicio', pathsToCheck: ['/', '/newly-registered', '/rating'] },
  '/gallery': { label: 'Galería' },
  [ACCOUNT]: {
    label: 'Mi cuenta',
    pathsToCheck: [
      '/account/personal-data',
      '/account/payment-methods',
      '/account/contact-data',
      '/account/billing',
      '/account/change-password',
      '/account/service-terms'
    ]
  },
  '/proposals': { label: 'Propuestas', pathsToCheck: ['/proposals/:id'] },
  '/billing': { label: 'Facturación' },
  '/calendar': { label: 'Calendario' },
  [BARBEIBOT]: { label: 'Barbei-bot' },
  [SUBSCRIPTIONS]: {
    label: 'Suscripciones',
    pathsToCheck: ['/subscriptions/:id']
  },
  '/media-check': { label: 'Configuración' },
  [NOTIFICATIONS]: { label: 'Notificaciones' }
}

function Badge({ count, selected }) {
  if (!count) return null
  return (
    <div
      className={[styles.badge, !selected ? styles.notSelectedBadge : ''].join(
        ' '
      )}
    >
      {count}
    </div>
  )
}

function NotificationsMenuItem({ to, selected, label }) {
  const [state, setState] = useState({ isFetching: false, count: 0 })

  const fetchCount = useCallback(() => {
    setState(assoc('isFetching', true))
    getNotificationCount()
      .then(count => setState(assoc('count', count)))
      .catch(e => {
        console.error('Error fetching notification count: ', e)
      })
      .finally(() => setState(assoc('isFetching', false)))
  }, [])
  useEffect(() => {
    fetchCount()
    // const interval = setInterval(() => {
    //   fetchCount()
    // }, 60000)
    // return () => {
    //   clearInterval(interval)
    // }
  }, [fetchCount])

  return (
    <Link to={to}>
      <div className={[styles.item, selected ? styles.selected : ''].join(' ')}>
        <div
          className={[
            styles.notifications,
            selected ? styles.selectedText : ''
          ].join(' ')}
        >
          <span>{label}</span>{' '}
          {state.isFetching ? (
            <DotsSpinner />
          ) : (
            <Badge count={state.count} selected={selected} />
          )}
        </div>
      </div>
    </Link>
  )
}

function MenuItem({ to, selected, label, color }) {
  if (to === NOTIFICATIONS)
    return <NotificationsMenuItem to={to} selected={selected} label={label} />
  return (
    <Link to={to}>
      <div className={[styles.item, selected ? styles.selected : ''].join(' ')}>
        <p
          className={[selected ? styles.selectedText : ''].join(' ')}
          style={{ color }}
        >
          {label}
        </p>
      </div>
    </Link>
  )
}

const isRouteActive = (pathname, route, pathsToCheck = []) => {
  const match = route === pathname
  const pathMatch = pathsToCheck.some(path =>
    matchPath({ path, end: true }, pathname)
  )
  return !!match || !!pathMatch
}
function LateralMenu() {
  const { pathname } = useLocation()
  const { student } = useAuth()

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        {Object.keys(Options).map(key => {
          if (student.accessType === AccessTypes.TRAINEE && key === ACCOUNT)
            return null
          const option = Options[key]
          const isSelected = isRouteActive(pathname, key, option.pathsToCheck)
          if (!student.barbeibotAccess && key === BARBEIBOT) return null
          if (
            student.accessType !== AccessTypes.STUDENT &&
            key === SUBSCRIPTIONS
          )
            return null
          if (config.hiddenMenuItems.split(',').includes(key)) return null
          return (
            <MenuItem
              key={key}
              to={key}
              selected={isSelected}
              label={option.label}
              color={option.color} //TODO hack
            />
          )
        })}
      </div>
    </section>
  )
}

export default LateralMenu

import React, { useState } from 'react'
import { getBorderImage } from 'utils/colors'
import RingsSpinner from 'components/spinners/RingsSpinner'
import styles from './MediaCard.module.css'

function MediaCard({
  index = 0,
  imgUrl = '',
  title = '',
  category = '',
  href = '',
  detail = '',
  backgroundPosition = 'center'
}) {
  const [isLoading, setIsLoading] = useState(true)
  return (
    <div
      className={styles.card}
      style={{
        backgroundImage: 'url(' + imgUrl + ')',
        backgroundSize: 'cover',
        backgroundPosition,
        backgroundRepeat: 'no-repeat',
        borderImage: getBorderImage(index)
      }}
    >
      <a className={styles.a} href={href} target='_blank' draggable={false}>
        {isLoading && (
          <img
            src={imgUrl}
            onLoad={() => setIsLoading(false)}
            onError={() => setIsLoading(false)}
            hidden
          />
        )}
        {isLoading ? (
          <RingsSpinner />
        ) : (
          <>
            <div className={styles.category}>{category}</div>
            <p className={styles.title}>{title}</p>
            <span className={styles.detail}>{detail}</span>
          </>
        )}
      </a>
    </div>
  )
}
export default MediaCard

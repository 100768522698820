//@ts-check
import React, { useEffect, useState } from 'react'
import { assocPath, dissocPath } from 'ramda'
import { findIncomes, findPayments } from 'api/billing'
import { dateToString } from 'utils/date'
import { format } from 'utils/math'
import Chevron from 'assets/icons/ChevronBottom'
import { Paragraph } from 'components/typography'
import Tag from 'components/tags/Tag'
import DotsSpinner from 'components/spinners/DotsSpinner'
import Incomes from './Incomes'
import styles from './PaymentTab.module.css'

function PaymentTab() {
  const [state, setState] = useState({
    isFetching: false,
    payments: [],
    incomes: [],
    open: {}
  })

  const handleOpen = id =>
    setState(prevState => {
      let path = ['open', id]
      if (state.open[id]) return dissocPath(path, prevState)
      return assocPath(path, true, prevState)
    })

  useEffect(() => {
    const fetchData = async () => {
      setState(assocPath(['isFetching'], true))
      try {
        const payments = await findPayments()
        const incomes = await findIncomes()
        setState(prev => ({
          ...prev,
          payments: payments.sort((a, b) => b.fecha - a.fecha),
          incomes
        }))
      } catch (error) {
      } finally {
        setState(assocPath(['isFetching'], false))
      }
    }
    fetchData()
  }, [])

  const pendingIncomes = state.incomes.filter(income => {
    const pagos = state.payments.filter(payment =>
      payment.ingresos.some(ingresoPago => ingresoPago.id === income.id)
    )
    return pagos.length === 0
  })

  return (
    <section className={styles.section}>
      {state.isFetching && (
        <div className={styles.spinner}>
          <DotsSpinner />
        </div>
      )}
      <div className={styles.container}>
        {!state.isFetching && !state.payments.length && (
          <Paragraph>Actualmente no tienes ningún pago.</Paragraph>
        )}
        {!!state.payments.length && <Header />}
        {state.payments.map(payment => {
          const isOpen = !!state.open[payment.id]
          return (
            <details key={payment.id} className={styles.details}>
              <summary
                className={[styles.summary, isOpen ? styles.isOpen : ''].join(
                  ' '
                )}
                onClick={() => handleOpen(payment.id)}
              >
                <div className={styles.summaryRow}>
                  <Paragraph type='body1Bold' className={styles.summaryTitle}>
                    {payment.concepto}
                  </Paragraph>
                  <Paragraph className={styles.summaryPaymentData}>
                    {payment.tipo}
                  </Paragraph>
                  <Paragraph type='body2' className={styles.summaryPaymentData}>
                    {dateToString(payment.fecha)}
                  </Paragraph>
                  <Paragraph className={styles.summaryPaymentData}>
                    {format(payment.importePago)} €
                  </Paragraph>
                  <div className={styles.summaryPaymentData}>
                    <PromotionalCode payment={payment} />
                  </div>
                  <div className={styles.summaryLastColumn}>
                    <Paragraph
                      type='body2'
                      className={styles.summaryPaymentData}
                    >
                      <PaymentStatus payment={payment} />
                    </Paragraph>
                    {isOpen ? (
                      <Chevron className={styles.chevronOpen} />
                    ) : (
                      <Chevron className={styles.chevronClose} />
                    )}
                  </div>
                </div>
              </summary>
              <div className={styles.summaryContent}>
                <Incomes incomes={payment?.ingresos || []} />
              </div>
            </details>
          )
        })}
      </div>
      {!state.isFetching && (
        <Incomes
          incomes={pendingIncomes}
          title='Ingresos pendientes'
          emptyInfo='No hay ningún ingreso pendiente de asignar'
        />
      )}
    </section>
  )
}

export default PaymentTab

const Header = () => (
  <div className={styles.summaryHeader}>
    <Paragraph type='body1Bold' className={styles.summaryFirstHeaderCell}>
      Concepto
    </Paragraph>
    {['Tipo', 'Fecha', 'Importe', 'Promoción', 'Estado'].map(h => (
      <Paragraph key={h} type='body1Bold' className={styles.summaryHeaderCell}>
        {h}
      </Paragraph>
    ))}
  </div>
)

const Placeholder = ({ content }) => (
  <Paragraph type='body2' className={styles.placeholder}>
    {content}
  </Paragraph>
)
const PromotionalCode = ({ payment }) => {
  if (
    !payment.codigosPromocionales ||
    payment.codigosPromocionales.length === 0
  ) {
    return <Placeholder content='Sin promociones' />
  }
  return payment.codigosPromocionales.map(promo => (
    <Tag key={promo} className={styles.promo} label={promo} />
  ))
}

const PaymentStatus = ({ payment }) => {
  const datos = {
    status: 'Pagado',
    content: 'Pagado'
  }
  if (payment.importeRestante > 0) {
    datos.status = 'Pendiente'
    datos.content = `${format(payment.importeRestante)} €`
  }
  if (payment.importeRestante < 0) {
    datos.status = 'Exceso'
    datos.content = `+${format(-payment.importeRestante)} €`
  }
  return (
    <strong className={styles.status} data-status={datos.status}>
      {datos.content}
    </strong>
  )
}

import React, { useCallback, useMemo, useState } from 'react'
import QRCode from 'qrcode.react'
import { isSameDay } from 'date-fns'
import ChevronLeft from 'assets/icons/ChevronLeft'
import ChevronRight from 'assets/icons/ChevronRight'
import {
  AttendanceStatus,
  AttendanceStatusLabels,
  AttendanceTypeLabels
} from 'utils/constants'
import {
  DAY_NAME_BY_NUMBER,
  formatDate,
  getMonthDaysInfo,
  isPast,
  toTimeString
} from 'utils/date'
import { noop } from 'utils'
import { generateCalendarLink, generateICSFile, makeIcalEvent } from 'utils/ics'
import config from 'config'
import DotsSpinner from 'components/spinners/DotsSpinner'
import Anchor from 'components/anchor/Anchor'
import { H3, Paragraph } from 'components/typography'
import Tooltip from 'components/tooltips/Tooltip'
import Modal from 'components/modals/Modal'
import RatingButton from 'components/buttons/Rating'
import styles from './AttendanceCalendar.module.css'

const MAX_VISIBLE_SUBJECT_LENGTH = 12
const MAX_VISIBLE_TEACHER_LENGTH = 16
function getVisibleSubjectName(name = '-') {
  if (name.length < MAX_VISIBLE_SUBJECT_LENGTH) return name
  return name.substring(0, MAX_VISIBLE_SUBJECT_LENGTH) + '...'
}
function getVisibleTeacherName(name = '-') {
  if (name.length < MAX_VISIBLE_TEACHER_LENGTH) return name
  return name.substring(0, MAX_VISIBLE_TEACHER_LENGTH) + '...'
}

function AttendanceCalendar({
  attendances = [],
  ratingByAttendanceId = {},
  month,
  year,
  disabled = false,
  isLoading = false,
  onChangeMonth = noop,
  onEdit = noop
}) {
  const [{ selectedYear, selectedMonth }, setMonthYear] = useState({
    selectedYear: year || new Date().getFullYear(),
    selectedMonth: month || new Date().getMonth()
  })
  const [selectedEvent, setSelectedEvent] = useState(null)

  const weeks = useMemo(
    () => getMonthDaysInfo(selectedYear, selectedMonth),
    [selectedYear, selectedMonth]
  )

  const handleChangeMonth = useCallback(
    amount => {
      let newYear = selectedYear
      let newMonth = selectedMonth + amount
      if (newMonth === 12) {
        newMonth = 0
        newYear++
      } else if (newMonth === -1) {
        newMonth = 11
        newYear--
      }
      setMonthYear(state => ({
        ...state,
        selectedYear: newYear,
        selectedMonth: newMonth
      }))
      onChangeMonth({ month: newMonth, year: newYear })
    },
    [selectedMonth, selectedYear, onChangeMonth]
  )

  const handleAddToCalendar = event => setSelectedEvent(event)

  return (
    <div className={styles.container}>
      <Actions
        label={formatDate({
          date: new Date(selectedYear, selectedMonth),
          stringFormat: 'MMMM, yyyy'
        })}
        attendances={attendances}
        isLoading={isLoading}
        disabled={disabled}
        onChange={handleChangeMonth}
      />
      <div className={styles.calendarWrapper}>
        <table className={styles.calendar}>
          <thead>
            <tr>
              {DAY_NAME_BY_NUMBER.map(({ label, day }) => {
                return (
                  <th key={day} className={styles.calendarHeader}>
                    {label.substring(0, 3)}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {weeks.map((week, i) => (
              <WeekRow
                key={i}
                week={week}
                selectedMonth={selectedMonth}
                attendances={attendances}
                ratingByAttendanceId={ratingByAttendanceId}
                onEdit={onEdit}
                onAddToCalendar={handleAddToCalendar}
              />
            ))}
          </tbody>
        </table>
      </div>
      {selectedEvent && (
        <Modal
          showActions={false}
          onCancel={() => setSelectedEvent(null)}
          showCloseIcon
        >
          <div className={styles.calendarModal}>
            <H3>Selecciona tu calendario preferido</H3>
            <Anchor
              type='secondary'
              href={generateCalendarLink(selectedEvent, 'google')}
            >
              Google Calendar
            </Anchor>
            <Anchor
              type='secondary'
              href={generateCalendarLink(selectedEvent, 'outlook')}
            >
              Outlook Calendar
            </Anchor>
            <Anchor
              type='secondary'
              href={generateCalendarLink(selectedEvent, 'yahoo')}
            >
              Yahoo Calendar
            </Anchor>
            <span
              className={styles.downloadICSLink}
              onClick={() => generateCalendarLink(selectedEvent, 'ics')}
            >
              Guardar en calendario local
            </span>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default AttendanceCalendar

function Actions({ label, attendances = [], isLoading, disabled, onChange }) {
  return (
    <div className={styles.actionsContainer}>
      <div className={styles.actions}>
        <ChevronLeft
          className={[
            styles.chevron,
            disabled ? styles.chevronDisabled : ''
          ].join(' ')}
          onClick={() => onChange(-1)}
        />
        <span className={styles.title}>
          {label} {isLoading && <DotsSpinner />}
        </span>
        <ChevronRight
          className={[
            styles.chevron,
            disabled ? styles.chevronDisabled : ''
          ].join(' ')}
          onClick={() => onChange(1)}
        />
      </div>
      <div className={styles.alert}>
        Si algo no es correcto,{' '}
        <Tooltip
          classNames={{ title: styles.qr }}
          placement='bottom'
          title={
            <div>
              <QRCode
                value={`https://wa.me/${config.whatsAppNumber}?text=Hola! Me gustaría confirmar una asistencia de mi calendario`}
                size={180}
              />
              <Paragraph type='body2Bold'>
                WhatsApp: +{config.whatsAppNumber}
              </Paragraph>
            </div>
          }
        >
          <Anchor
            type='secondary'
            href={`https://wa.me/${config.whatsAppNumber}?text=¡Hola! Me gustaría confirmar una asistencia de mi calendario`}
          >
            <span title='Haz clic'>escríbenos por WhatsApp</span>
          </Anchor>
        </Tooltip>
      </div>
      <div className={styles.downloadICSContainer}>
        <Tooltip
          classNames={{ title: styles.downloadICSTooltip }}
          title='Descargará un archivo ICS que puedes abrir en tu ordenador y añadir a tu calendario preferido'
          placement='left'
        >
          <span
            className={styles.downloadICSButton}
            onClick={() =>
              generateICSFile(
                attendances
                  .filter(a => !isPast(new Date(a.classStart), new Date()))
                  .map(makeIcalEvent)
              )
            }
          >
            Guardar calendario
          </span>
        </Tooltip>
      </div>
    </div>
  )
}

function WeekRow({
  week,
  selectedMonth,
  attendances,
  ratingByAttendanceId,
  onEdit,
  onAddToCalendar
}) {
  return (
    <tr className={styles.calendarWeek}>
      {week.map((day, i) => (
        <DayCell
          key={i}
          day={day}
          selectedMonth={selectedMonth}
          attendances={attendances}
          ratingByAttendanceId={ratingByAttendanceId}
          onEdit={onEdit}
          onAddToCalendar={onAddToCalendar}
        />
      ))}
    </tr>
  )
}

function DayCell({
  day,
  selectedMonth,
  attendances,
  ratingByAttendanceId,
  onEdit,
  onAddToCalendar
}) {
  let dayClassName = [styles.dateIdentifier]
  let cellClassName = [styles.calendarDay]
  let sameMonth = day.getMonth() === selectedMonth
  if (sameMonth) {
    dayClassName.push(styles.dateIdentifierActive)
    cellClassName.push(styles.calendarCurrenteMonth)
  }
  if (isSameDay(new Date(), day)) {
    dayClassName.push(styles.dateIdentifierToday)
    cellClassName.push(styles.calendarDayToday)
  }
  return (
    <td className={cellClassName.join(' ')}>
      <div className={dayClassName.join(' ')}>{day.getDate()}</div>

      {attendances.map((attendance, i) => {
        if (isSameDay(new Date(attendance.classStart), day)) {
          const rating = ratingByAttendanceId[attendance.id]
          return (
            <div
              key={i}
              className={styles.attendanceWrapper}
              onClick={() => onEdit(attendance.id)}
            >
              <div className={styles.attendanceInfo}>
                <Tooltip title={AttendanceStatusLabels[attendance.status]}>
                  <span
                    className={[
                      styles.attendanceCircle,
                      styles[`--${attendance.status}`]
                    ].join(' ')}
                  />
                </Tooltip>
                <Tooltip
                  title={`${attendance.subjectName || '-'} (${
                    attendance.subjectLevel || '-'
                  })`}
                >
                  <span>{getVisibleSubjectName(attendance.subjectName)}</span>
                </Tooltip>
              </div>
              {!!attendance.teacherName && (
                <div className={styles.attendanceInfo}>
                  <Tooltip title={<span>{attendance.teacherName}</span>}>
                    <span>{getVisibleTeacherName(attendance.teacherName)}</span>
                  </Tooltip>
                </div>
              )}
              <div className={styles.attendanceInfo}>
                <Tooltip
                  title={<span>{AttendanceTypeLabels[attendance.type]}</span>}
                >
                  <span>
                    {toTimeString(attendance.classStart)} -{' '}
                    {toTimeString(attendance.classEnd)}
                  </span>
                </Tooltip>
              </div>
              {!isPast(new Date(attendance.classStart), new Date()) && (
                <div className={styles.attendanceInfo}>
                  <span
                    className={styles.calendarLink}
                    onClick={() => onAddToCalendar(makeIcalEvent(attendance))}
                  >
                    Añadir a calendario
                  </span>
                </div>
              )}
              {sameMonth && attendance.status === AttendanceStatus.VIENE && (
                <div className={styles.ratingContainer}>
                  <RatingButton attendance={attendance} rating={rating} />
                </div>
              )}
            </div>
          )
        }
        return null
      })}
    </td>
  )
}

//@ts-check
import React from 'react'
import { take } from 'ramda'
import { dateToString, isSameDay, toTimeString } from 'utils/date'
import BrainOn from 'assets/svg/BrainOn'
import Vector from 'assets/icons/Vector'
import Button from 'components/buttons/Button'
import { Paragraph } from 'components/typography'
import RatingButton from 'components/buttons/Rating'
import styles from './AttendanceHighlightCard.module.css'

/**
 * @typedef AttendanceHighlightCardProps
 * @property {string} title
 * @property {Date} date
 * @property {object} attendance
 * @property {string} professorName
 * @property {Partial<import('components/buttons/Button').ButtonProps>} buttonProps
 * */

/**
 *
 * @param {Partial<AttendanceHighlightCardProps>} param0
 */
function AttendanceHighlightCard({
  title = '',
  date,
  attendance = {},
  buttonProps
}) {
  const dateText = isSameDay(new Date(date), new Date())
    ? 'Hoy'
    : take(2, dateToString(date).split('/')).join(' / ')
  return (
    <div className={styles.container}>
      <div className={styles.ratingContainer}>
        <RatingButton size='medium' attendance={attendance} />
      </div>
      <div className={styles.content}>
        <BrainOn color='var(--bright)' className={styles.imageSvg} />
        <Paragraph className={styles.title}>{title}</Paragraph>
        <div className={styles.subheader}>
          <Paragraph className={styles.paragraph}>
            <strong>{attendance.subjectName}</strong>
          </Paragraph>
          <div className={styles.classDetails}>
            <p>{toTimeString(date)}</p>
            <div className={styles.verticalDivider} />
            <p>{dateText}</p>
          </div>
          <Vector className={styles.vector} color='var(--seadapted)' />
        </div>
        <div>
          <Button
            type='accent-secondary'
            label='Entrar en clase'
            size='small'
            {...(buttonProps || {})}
          />
        </div>
      </div>
    </div>
  )
}

export default AttendanceHighlightCard

import React, { useCallback, useEffect, useState } from 'react'
import { dateToString, getCurrentMonth } from 'utils/date'
import { fetchPhotos } from 'api/photos'
import { H2, Paragraph } from 'components/typography'
import MonthSelect from 'components/selects/MonthSelect'
import YearSelect from 'components/selects/YearSelect'
import Button from 'components/buttons/Button'
import UploadPhotoModal from 'components/modals/UploadPhotoModal'
import styles from './ImageList.module.css'
import Modal from './Modal'

function ImageList({ onSelect, onClose }) {
  const [query, setQuery] = useState(getCurrentMonth())
  const [photos, setPhotos] = useState([])
  const [isFetching, setIsFetching] = useState(true)
  const [selectedImageUrl, setSelectedImageUrl] = useState(null)
  const [showUploadModal, setShowUploadModal] = useState(false)

  const fetchData = useCallback(() => {
    if (query.month && query.year) {
      setIsFetching(true)
      fetchPhotos({ month: query.month, year: query.year })
        .then(data => {
          const sortedPhotos = data.sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          )
          setPhotos(sortedPhotos)
        })
        .catch(error => {
          console.error('Error al obtener las fotos:', error)
        })
        .finally(() => setIsFetching(false))
    }
  }, [query])

  const handleDateChange = key => e => {
    const value = e.target.value
    setQuery(currentDate => ({
      ...currentDate,
      [key]: value
    }))
  }

  const openUploadModal = () => {
    setShowUploadModal(true)
  }
  const closeUploadModal = () => {
    setShowUploadModal(false)
  }
  const handleSelect = () => {
    onSelect(selectedImageUrl)
    onClose()
  }
  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <Modal
      okText='Cargar'
      onOk={handleSelect}
      onCancel={onClose}
      otherAction={
        <>
          <Button size='tiny' type='secondary' onClick={openUploadModal}>
            Subir foto
          </Button>
          <Button
            size='tiny'
            type='warning'
            loading={isFetching}
            disabled={!(query.month && query.year)}
            onClick={fetchData}
          >
            Refrescar
          </Button>
        </>
      }
      isLoading={isFetching}
      okDisabled={!selectedImageUrl}
      hideCancelButton
      showCloseIcon
    >
      <div className={styles.modal}>
        <H2>Comparte tus pensamientos 🧠</H2>
        <div className={styles.actionContainer}>
          <div className={styles.dateContainer}>
            <div className={styles.monthSelector}>
              <MonthSelect
                value={query.month}
                showLabel={false}
                onChange={handleDateChange('month')}
              />
            </div>
            <div className={styles.yearSelector}>
              <YearSelect
                value={query.year}
                showLabel={false}
                onChange={handleDateChange('year')}
              />
            </div>
          </div>
        </div>

        <div className={styles.photoContainer}>
          {photos.length > 0 ? (
            photos.map(photo => (
              <div
                key={photo.id}
                title='Haz doble clic'
                className={[
                  styles.photoItem,
                  selectedImageUrl === photo.location
                    ? styles.selectedPhoto
                    : ''
                ].join(' ')}
                onClick={() => setSelectedImageUrl(photo.location)}
                onDoubleClick={() => {
                  onSelect(photo.location)
                  onClose()
                }}
              >
                <div>
                  <img
                    key={photo.thumbnail}
                    className={styles.photo}
                    src={photo.thumbnail}
                    alt='Foto alumno'
                  />
                </div>
                <p className={styles.photoDate}> {dateToString(photo.date)}</p>
              </div>
            ))
          ) : (
            <Paragraph>No has subido ninguna foto este mes</Paragraph>
          )}
        </div>

        {showUploadModal && <UploadPhotoModal onClose={closeUploadModal} />}
      </div>
    </Modal>
  )
}

export default ImageList

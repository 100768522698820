//@ts-check
import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { noop } from 'utils'
import Cross from 'assets/icons/Cross'
import ChevronRight from 'assets/icons/ChevronRight'
import ChevronLeft from 'assets/icons/ChevronLeft'
import Reload from 'assets/icons/Reload'
import { Paragraph } from 'components/typography'
import styles from './GalleryModal.module.css'

function GalleryModal({
  initialIndex = null,
  photos = [],
  tabIndex = 102,
  onCancel = noop
}) {
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(initialIndex)
  const [rotation, setRotation] = useState(0)
  const modalRef = useRef(null)

  const handleNext = () =>
    setSelectedPhotoIndex(prevIndex => (prevIndex + 1) % photos.length)

  const handlePrev = () =>
    setSelectedPhotoIndex(
      prevIndex => (prevIndex - 1 + photos.length) % photos.length
    )

  const handleRotateRight = () => setRotation(prev => prev + 90)
  const handleRotateLeft = () => setRotation(prev => prev - 90)

  const handleKeyDown = e => {
    e.preventDefault()
    e.stopPropagation()
    if (e.key === 'Escape') return onCancel()
    if (e.key === 'ArrowLeft') return handlePrev()
    if (e.key === 'ArrowRight') return handleNext()
    if (e.key === 'ArrowUp') return handleRotateRight()
    if (e.key === 'ArrowDown') return handleRotateLeft()
  }

  useEffect(() => {
    modalRef.current?.focus()
  }, [])
  const hideArrows = photos.length < 2
  return ReactDOM.createPortal(
    <div
      ref={modalRef}
      className={styles.modal}
      onKeyDown={handleKeyDown}
      tabIndex={tabIndex}
    >
      <div className={styles.content}>
        <Cross
          className={styles.closeIcon}
          color='var(--bright)'
          onClick={onCancel}
        />
        <div className={styles.hints}>
          {!hideArrows && (
            <Paragraph className={styles.hint} type='body2'>
              <code>← →</code> para navegar
            </Paragraph>
          )}
          <Paragraph className={styles.hint} type='body2'>
            <code>↑ ↓</code> para girar
          </Paragraph>
          <Paragraph className={styles.hint} type='body2'>
            <code>Esc</code> para salir
          </Paragraph>
        </div>
        <div className={styles.rotateIconContainer}>
          <div className={styles.rotateLeftIcon} onClick={handleRotateLeft}>
            <Reload />
          </div>
          <div className={styles.rotateRightIcon} onClick={handleRotateRight}>
            <Reload />
          </div>
        </div>
        {!hideArrows && (
          <ChevronLeft
            id={String(selectedPhotoIndex)}
            className={`${styles.arrow} ${styles.leftArrow}`}
            onClick={handlePrev}
            color='var(--bright)'
          />
        )}
        <div className={styles.selectedPhotoContainer}>
          <img
            src={photos[selectedPhotoIndex]?.location}
            style={{
              transform: `rotate(${rotation}deg)`,
              maxWidth: rotation % 180 === 0 ? '90%' : 'calc(100vh - 120px)',
              maxHeight: rotation % 180 === 0 ? 'calc(100vh - 150px)' : '100%'
            }}
            alt={`Photo ${selectedPhotoIndex + 1}`}
            className={styles.selectedPhoto}
          />
        </div>
        {!hideArrows && (
          <ChevronRight
            id={String(selectedPhotoIndex)}
            className={`${styles.arrow} ${styles.rightArrow}`}
            onClick={handleNext}
            color='var(--bright)'
          />
        )}
      </div>
    </div>,
    document.getElementById('modal-root')
  )
}

export default GalleryModal

//@ts-check
import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { assocPath } from 'ramda'
import { saveRating } from 'api/rating'
import Cross from 'assets/icons/Cross'
import { RATING_LABELS, RATING_SCORES } from 'utils/constants'
import { useNotificationActions } from 'context/NotificationProvider'
import { H3, H4, Paragraph } from 'components/typography'
import Button from 'components/buttons/Button'
import TextArea from 'components/inputs/TextArea'
import styles from './RatingModal.module.css'

const COMMENTS_LIMIT = 500

function RatingModal({
  attendanceId = '',
  studentName,
  tabIndex = 103,
  onClose
}) {
  const [{ classRating, teacherRating, comments }, setState] = useState({
    classRating: '',
    teacherRating: '',
    comments: ''
  })
  const [isRating, setIsRating] = useState(false)
  const { setSuccessMessage, setErrorMessage } = useNotificationActions()
  const modalRef = useRef(null)

  const handleKeyDown = e => {
    if (e.key === 'Escape') return onClose()
  }
  const handleChange = e => {
    const { name, value } = e.target
    setState(assocPath([name], value))
  }

  const handleCommentsChange = e => {
    if (e.target.value.length > COMMENTS_LIMIT) return
    handleChange(e)
  }

  const handleSubmit = () => {
    setIsRating(true)
    saveRating({ attendanceId, classRating, teacherRating, comments })
      .then(() => {
        setIsRating(false)
        setSuccessMessage({
          message: 'Gracias por tu valoración. Para nosotros es muy importante'
        })
        onClose()
      })
      .catch(() => setErrorMessage({}))
      .finally(() => setIsRating(false))
  }

  useEffect(() => {
    modalRef.current?.focus()
  }, [])

  const disabled = !attendanceId || !teacherRating || !classRating

  return ReactDOM.createPortal(
    <div
      ref={modalRef}
      className={styles.modal}
      tabIndex={tabIndex}
      onKeyDown={handleKeyDown}
    >
      <div className={styles.content}>
        <Cross className={styles.closeIcon} onClick={onClose} />
        <H3>
          ¡Buen trabajo,{' '}
          <span className={styles.studentName}>{studentName}</span>!
        </H3>
        <H4 className={styles.h4}>
          Muchas gracias por confiar en nosotros.
          <br />
          Evalúa qué te ha parecido el profe y la clase 💫
        </H4>
        <div className={styles.ratingSection}>
          <Paragraph className={styles.paragraph} type='body2Bold'>
            Profe
          </Paragraph>
          {Object.keys(RATING_SCORES)
            .reverse()
            .map((score, i) => (
              <div
                key={i}
                className={[
                  styles.emoji,
                  score === teacherRating ? styles.selectedEmoji : '',
                  score === teacherRating &&
                  [RATING_SCORES.MUY_NEGATIVA, RATING_SCORES.NEGATIVA].includes(
                    teacherRating
                  )
                    ? styles.negative
                    : ''
                ].join(' ')}
                onClick={() =>
                  handleChange({
                    target: { name: 'teacherRating', value: score }
                  })
                }
              >
                {RATING_LABELS[score]}
              </div>
            ))}
        </div>
        <div className={styles.ratingSection}>
          <Paragraph className={styles.paragraph} type='body2Bold'>
            Clase
          </Paragraph>
          {Object.keys(RATING_SCORES)
            .reverse()
            .map((score, i) => (
              <div
                key={i}
                className={[
                  styles.emoji,
                  score === classRating ? styles.selectedEmoji : '',
                  score === classRating &&
                  [RATING_SCORES.MUY_NEGATIVA, RATING_SCORES.NEGATIVA].includes(
                    classRating
                  )
                    ? styles.negative
                    : ''
                ].join(' ')}
                onClick={() =>
                  handleChange({
                    target: { name: 'classRating', value: score }
                  })
                }
              >
                {RATING_LABELS[score]}
              </div>
            ))}
        </div>
        <div className={styles.commentsArea}>
          <TextArea
            name='comments'
            placeholder='Añade tus comentarios aquí por favor'
            value={comments}
            rows={5}
            onChange={handleCommentsChange}
          />
          <Paragraph
            className={styles.commentsLimit}
            type='body2Bold'
          >{`${comments.length} / ${COMMENTS_LIMIT}`}</Paragraph>
        </div>
        <Button
          label={isRating ? 'Valorando...' : 'Valorar'}
          size='small'
          disabled={disabled || isRating}
          onClick={handleSubmit}
        />
      </div>
    </div>,
    document.getElementById('modal-root')
  )
}

export default RatingModal

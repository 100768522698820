import React, { useState } from 'react'
import { formatDate } from 'utils/date'
import Content from 'components/barbeibot/Content'
import { H2, Paragraph } from 'components/typography'
import Modal from './Modal'
import GalleryModal from './GalleryModal'
import styles from './BarbeibotReview.module.css'

function BarbeibotReview({ question = {}, onClose }) {
  const [showZoom, setShowZoom] = useState(false)

  const photo = question.urlImagen

  return (
    <Modal onCancel={onClose} showActions={false} showCloseIcon>
      <div className={styles.modal}>
        <H2>Barbei-bot review 🧐</H2>
        <Paragraph className={styles.selectedDate} type='body2Bold'>
          {formatDate({
            date: question.fecha,
            stringFormat: 'EEEE, d MMMM Y - HH:mm'
          })}
        </Paragraph>
        <div className={styles.photoArea}>
          {photo ? (
            <div className={styles.imgContainer}>
              <img
                className={styles.img}
                src={photo}
                onClick={() => setShowZoom(true)}
              />
            </div>
          ) : (
            <div className={styles.emptyImg}>
              <Paragraph type='body2'>Sin imagen</Paragraph>
            </div>
          )}
        </div>
        <Paragraph className={styles.paragraph} type='body2Bold'>
          Pregunta:
        </Paragraph>
        <Paragraph className={styles.answer}>
          {question.pregunta || '-'}
        </Paragraph>
        <Paragraph className={styles.paragraph} type='body2Bold'>
          Respuesta:
        </Paragraph>
        <Content className={styles.markdown} content={question.respuesta} />
        {showZoom && (
          <GalleryModal
            initialIndex={0}
            photos={[{ location: photo }]}
            onCancel={() => setShowZoom(false)}
          />
        )}
      </div>
    </Modal>
  )
}

export default BarbeibotReview

import React, { useEffect, useState } from 'react'
import Menu from 'assets/icons/Menu'
import Cross from 'assets/icons/Cross'
import { findQuestionsByMonthYear, getQuestionsByDate } from 'api/barbeibot'
import { toTimeString } from 'utils/date'
import { H3, Paragraph } from 'components/typography'
import BarbeibotReview from 'components/modals/BarbeibotReview'
import SimpleIndicatorCalendar from 'components/calendars/SimpleIndicatorCalendar'
import styles from './RightMenu.module.css'

function sortQuestions(a, b) {
  return b.fecha - a.fecha
}

function RightMenu({ todayProgress }) {
  const [show, setShow] = useState(true)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [questions, setQuestions] = useState([])
  const [monthlyQuestions, setMonthlyQuestions] = useState({})
  const [isFetching, setIsFetching] = useState(true)
  const [selectedQuestion, setSelectedQuestion] = useState(null)

  const selectedMonth = selectedDate.getMonth()
  const selectedYear = selectedDate.getFullYear()

  useEffect(() => {
    setIsFetching(true)
    getQuestionsByDate(selectedDate.getTime())
      .then(setQuestions)
      .catch(() => {})
      .finally(() => setIsFetching(false))
  }, [selectedDate, todayProgress])
  useEffect(() => {
    setIsFetching(true)
    findQuestionsByMonthYear(selectedMonth, selectedYear)
      .then(setMonthlyQuestions)
      .catch(() => {})
      .finally(() => setIsFetching(false))
  }, [selectedMonth, selectedYear, todayProgress])

  if (!show)
    return (
      <div className={styles.menuIcon} onClick={() => setShow(true)}>
        <Menu color='var(--purpletronic)' />
      </div>
    )
  return (
    <div
      className={[styles.rightMenu, !show ? styles.rightMenuHide : ''].join(
        ' '
      )}
    >
      <div className={styles.header}>
        <H3 className={styles.title}>
          Histórico <span>{`(${questions.length})`}</span>
        </H3>
        <div className={styles.closeIcon} onClick={() => setShow(false)}>
          <Cross id='menu' color='var(--bright)' />
        </div>
      </div>

      <div className={styles.historyList}>
        {!questions?.length && !isFetching && (
          <Paragraph type='body2Bold'>No hay consultas</Paragraph>
        )}
        {isFetching && <Paragraph type='body2Bold'>Buscando...</Paragraph>}
        {questions.sort(sortQuestions).map((question, index) => (
          <div key={index} className={styles.questionItem}>
            <div className={styles.questionText}>
              {question.pregunta || 'Sin pregunta'}
            </div>
            <div className={styles.questionResponse}>{question.respuesta}</div>
            <div className={styles.actions}>
              <Paragraph className={styles.hour} type='body2Bold'>
                {toTimeString(question.fecha)}
              </Paragraph>
              <button
                className={styles.actionButton}
                onClick={() => setSelectedQuestion(question)}
              >
                Ver
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.calendar}>
        <SimpleIndicatorCalendar
          selectedDate={selectedDate}
          data={monthlyQuestions}
          isLoading={isFetching}
          onChangeDate={setSelectedDate}
        />
      </div>
      {selectedQuestion && (
        <BarbeibotReview
          question={selectedQuestion}
          onClose={() => setSelectedQuestion(null)}
        />
      )}
    </div>
  )
}
export default RightMenu

import { makeQueryString } from 'utils/queries'
import { GET, POST } from './index'

export const findAttendanceRatings = (month, year) =>
  GET({
    url: 'students/rating/?' + makeQueryString({ month, year })
  })

export const getAttendanceRating = id =>
  GET({ url: 'students/rating/get-attendance-rating/' + id })

export const saveRating = (data = {}) => POST({ url: 'students/rating', data })

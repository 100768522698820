import React, { useMemo } from 'react'
import { isEmpty } from 'ramda'
import { H4, Paragraph } from 'components/typography'
import Vector from 'assets/icons/Vector'
import styles from './SubscriptionCard.module.css'

function SubscriptionCard({ subscription = {} }) {
  const { title, isPack, centro, hours } = useMemo(() => {
    if (!subscription || isEmpty(subscription)) return {}
    const {
      centro,
      //Rate
      mes,
      tarifa,
      //Pack
      pack,
      horas
    } = subscription || {}
    const isPack = !!pack
    const aux = mes
      ? mes.charAt(0).toUpperCase() + mes.slice(1).toLowerCase()
      : ''

    return {
      title: isPack ? pack : [tarifa, aux].join(' ').trim(),
      isPack,
      centro,
      hours: Number(horas)
    }
  }, [subscription])

  return (
    <div className={styles.card}>
      <H4 className={styles.title}>{title}</H4>
      <div className={styles.textInfo}>
        <Paragraph>{centro}</Paragraph>
      </div>
      <Vector className={styles.vector} color='var(--seadapted)' />
      <div className={styles.textInfo}>
        {!!hours && <Paragraph type='body2'>{hours} horas</Paragraph>}
        {!isPack && <Paragraph type='body2'>Duración mensual</Paragraph>}
      </div>
    </div>
  )
}

export default SubscriptionCard

import React, { useCallback, useEffect, useState } from 'react'
import { assocPath } from 'ramda'
import { findAttendances } from 'api/attendances'
import { findAttendanceRatings } from 'api/rating'
import { useNotificationActions } from 'context/NotificationProvider'
import { useAuth } from 'context/AuthProvider'
import { AccessTypes } from 'utils/constants'
import { H2 } from 'components/typography'
import AttendanceCalendar from 'components/calendars/AttendanceCalendar'
import styles from './Calendar.module.css'

function Calendar() {
  const [attendances, setAttendances] = useState([])
  const [ratingByAttendanceId, setRatingByAttendanceId] = useState({})
  const [isFetching, setIsFetching] = useState(true)
  const [{ month, year }, setMonthYear] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear()
  })
  const { student } = useAuth()
  const { setErrorMessage } = useNotificationActions()

  const fetchData = useCallback(async () => {
    try {
      setIsFetching(true)
      const isStudent = student.accessType == AccessTypes.STUDENT
      const attendances = await findAttendances(month, year)
      const ratings = isStudent ? await findAttendanceRatings(month, year) : []

      setAttendances(attendances)
      setRatingByAttendanceId(
        ratings.reduce(
          (acc, rating) => assocPath([rating.asistenciaId], rating, acc),
          {}
        )
      )
    } catch (error) {
      setErrorMessage({
        message: 'No hemos podido encontrar tus asistencias del mes 😔'
      })
    } finally {
      setIsFetching(false)
    }
  }, [month, setErrorMessage, year, student])

  useEffect(() => {
    fetchData()
  }, [fetchData])
  return (
    <section className={styles.section}>
      <H2>Calendario 🗓</H2>
      <div className={styles.calendar}>
        <AttendanceCalendar
          attendances={attendances}
          ratingByAttendanceId={ratingByAttendanceId}
          month={month}
          year={year}
          disabled={isFetching}
          isLoading={isFetching}
          onChangeMonth={setMonthYear}
        />
      </div>
    </section>
  )
}

export default Calendar

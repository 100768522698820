import * as React from 'react'

const Cross = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.293 8.293a1 1 0 0 0 0 1.414L13.586 15l-5.293 5.293a1 1 0 1 0 1.414 1.414L15 16.414l5.293 5.293a1 1 0 0 0 1.414-1.414L16.414 15l5.293-5.293a1 1 0 0 0-1.414-1.414L15 13.586 9.707 8.293a1 1 0 0 0-1.414 0Z'
      fill={props.color || '#8080F8'}
    />
    <mask
      id={'Cross' + (props.id || '-cross')}
      style={{
        maskType: 'luminance'
      }}
      maskUnits='userSpaceOnUse'
      x={8}
      y={8}
      width={14}
      height={14}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.293 8.293a1 1 0 0 0 0 1.414L13.586 15l-5.293 5.293a1 1 0 1 0 1.414 1.414L15 16.414l5.293 5.293a1 1 0 0 0 1.414-1.414L16.414 15l5.293-5.293a1 1 0 0 0-1.414-1.414L15 13.586 9.707 8.293a1 1 0 0 0-1.414 0Z'
        fill='#fff'
      />
    </mask>
    <g mask={`url(#Cross${props.id || '-cross'})`}>
      <path fill={props.color || '#8080F8'} d='M0 0h30v30H0z' />
    </g>
  </svg>
)

export default Cross

//@ts-check
import React, { useEffect, useState } from 'react'
import { dateToString, getCurrentMonth } from 'utils/date'
import { fetchPhotos } from 'api/photos'
import { H2, Paragraph } from 'components/typography'
import MonthSelect from 'components/selects/MonthSelect'
import YearSelect from 'components/selects/YearSelect'
import Button from 'components/buttons/Button'
import GalleryModal from 'components/modals/GalleryModal'
import UploadPhotoModal from 'components/modals/UploadPhotoModal'
import styles from './Gallery.module.css'

function Gallery() {
  const [query, setQuery] = useState(getCurrentMonth())
  const [photos, setPhotos] = useState([])
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null)
  const [showUploadModal, setShowUploadModal] = useState(false)

  useEffect(() => {
    if (query.month && query.year) {
      fetchPhotos({ month: query.month, year: query.year })
        .then(data => {
          const sortedPhotos = data.sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          )
          setPhotos(sortedPhotos)
        })
        .catch(error => {
          console.error('Error al obtener las fotos:', error)
        })
    }
  }, [query])

  const handleDateChange = key => e => {
    const value = e.target.value
    setQuery(currentDate => ({
      ...currentDate,
      [key]: value
    }))
  }

  const openUploadModal = () => {
    setShowUploadModal(true)
  }
  const closeUploadModal = () => {
    setShowUploadModal(false)
  }

  return (
    <section className={styles.section}>
      <H2>Comparte tus pensamientos 🧠</H2>
      <div className={styles.actionContainer}>
        <div className={styles.dateContainer}>
          <div className={styles.monthSelector}>
            <MonthSelect
              value={query.month}
              showLabel={false}
              onChange={handleDateChange('month')}
            />
          </div>
          <div className={styles.yearSelector}>
            <YearSelect
              value={query.year}
              showLabel={false}
              onChange={handleDateChange('year')}
            />
          </div>
        </div>
        <Button size='small' onClick={openUploadModal}>
          Subir foto
        </Button>
      </div>

      <div className={styles.photoContainer}>
        {photos.length > 0 ? (
          photos.map((photo, index) => (
            <div
              key={photo.id}
              className={styles.photoItem}
              onClick={() => setSelectedPhotoIndex(index)}
            >
              <div>
                <img
                  key={photo.thumbnail}
                  className={styles.photo}
                  src={photo.thumbnail}
                  alt='Foto alumno'
                />
              </div>
              <p className={styles.photoDate}> {dateToString(photo.date)}</p>
            </div>
          ))
        ) : (
          <Paragraph>No has subido ninguna foto este mes</Paragraph>
        )}
      </div>
      {selectedPhotoIndex !== null && (
        <GalleryModal
          initialIndex={selectedPhotoIndex}
          photos={photos}
          onCancel={() => setSelectedPhotoIndex(null)}
        />
      )}
      {showUploadModal && <UploadPhotoModal onClose={closeUploadModal} />}
    </section>
  )
}

export default Gallery

import React, { useCallback, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import BarbeibotLogo from 'assets/logos/Barbeibot'
import Cross from 'assets/icons/Cross'
import Send from 'assets/icons/Send'
import Reload from 'assets/icons/Reload'
import { getTodayProgress } from 'api/barbeibot'
import useAgoraBarbeibot from 'hooks/useAgoraBarbeibot'
import { useAuth } from 'context/AuthProvider'
import { formatDate } from 'utils/date'
import { Paragraph } from 'components/typography'
import DotsSpinner from 'components/spinners/DotsSpinner'
import TextArea from 'components/inputs/TextArea'
import Button from 'components/buttons/Button'
import ImageList from 'components/modals/ImageList'
import GalleryModal from 'components/modals/GalleryModal'
import Alert from 'components/alert/Alert'
import Content from 'components/barbeibot/Content'
import RightMenu from './RightMenu'
import styles from './Barbeibot.module.css'

const LIMIT = 20

function Barbeibot() {
  const [todayProgress, setTodayProgress] = useState(0)
  const [isFetching, setIsFetching] = useState(true)
  const [showImageList, setShowImageList] = useState(false)
  const [rotation, setRotation] = useState(0)
  const [showZoom, setShowZoom] = useState(false)
  const [question, setQuestion] = useState('')
  const { student } = useAuth()
  const { gpt, handleSubmit, handleDrop } = useAgoraBarbeibot()

  const fetchTodayProgress = useCallback(() => {
    setIsFetching(true)
    getTodayProgress()
      .then(setTodayProgress)
      .catch(() => {})
      .finally(() => setIsFetching(false))
  }, [])

  const handleRotateRight = () => setRotation(prev => prev + 90)
  const handleRotateLeft = () => setRotation(prev => prev - 90)

  useEffect(() => {
    fetchTodayProgress()
  }, [fetchTodayProgress])

  const answer = gpt.message || ''
  const photo = gpt.image
  const isLoading = gpt.isLoading
  const disabledSendButton = isLoading || (!question && !photo)
  const disabledAll = todayProgress >= LIMIT

  if (!student.barbeibotAccess) return <Navigate to='/' />
  return (
    <section className={styles.section}>
      <div className={styles.leftContent}>
        <div className={styles.header}>
          <BarbeibotLogo className={styles.barbeibotLogo} />
          {isFetching ? (
            <div className={styles.dotsSpinner}>
              <DotsSpinner />
            </div>
          ) : (
            <Paragraph className={styles.limit} type='body2Bold'>
              Preguntas realizadas hoy:{' '}
              <span>
                {todayProgress} / {LIMIT}
              </span>{' '}
            </Paragraph>
          )}
        </div>
        <Paragraph className={styles.selectedDate} type='body2Bold'>
          {formatDate({
            date: new Date(),
            stringFormat: 'EEEE, d MMMM Y'
          })}
        </Paragraph>

        <div className={styles.alert}>
          <Alert
            type='tertiary'
            title='Aviso Importante'
            message='Recuerda que esta es una herramienta educacional. Su mal uso puede llevar a restricciones de acceso.'
          />
          {disabledAll && (
            <Alert
              title='Información'
              message='Has alcanzado tu límite diario de preguntas'
              showCloseIcon={false}
            />
          )}
        </div>

        <div className={styles.questionArea}>
          {photo ? (
            <div className={styles.imgContainer}>
              <img
                className={styles.img}
                style={{ transform: `rotate(${rotation}deg)` }}
                src={photo}
                onClick={() => setShowZoom(true)}
              />
              <div className={styles.rotateIconContainer}>
                <div
                  className={styles.rotateLeftIcon}
                  onClick={handleRotateLeft}
                >
                  <Reload />
                </div>
                <div
                  className={styles.rotateRightIcon}
                  onClick={handleRotateRight}
                >
                  <Reload />
                </div>
              </div>
              <Cross
                id='photo'
                className={styles.crossIcon}
                color='var(--bright)'
                onClick={() => {
                  handleDrop()
                  setRotation(0)
                }}
              />
            </div>
          ) : (
            <div
              className={[
                styles.emptyImg,
                disabledAll ? styles.disabled : ''
              ].join(' ')}
              onClick={() => !disabledAll && setShowImageList(true)}
            >
              <Paragraph type='body2'>Selecciona una imagen</Paragraph>
            </div>
          )}
          <div className={styles.interaction}>
            <TextArea
              placeholder='Haz tus preguntas a Barbei-bot.'
              className={styles.textArea}
              value={question}
              onChange={e => !disabledAll && setQuestion(e.target.value)}
            />
          </div>
        </div>
        <div className={styles.sendButton}>
          <Button
            size='tiny'
            disabled={disabledSendButton || disabledAll}
            onClick={async () => {
              await handleSubmit(question)
              fetchTodayProgress()
            }}
          >
            {isLoading ? (
              <div className={styles.spinner}>
                <DotsSpinner />
              </div>
            ) : (
              <div className={styles.sendButtonText}>
                Preguntar
                <Send
                  className={[
                    styles.sendIcon,
                    disabledSendButton || disabledAll ? styles.disabled : ''
                  ].join(' ')}
                  color={
                    disabledSendButton || disabledAll
                      ? 'var(--disabled-color)'
                      : 'var(--seadapted)'
                  }
                />
              </div>
            )}
          </Button>
        </div>
        <Content className={styles.markdown} content={answer} />
      </div>
      <RightMenu todayProgress={todayProgress} />
      {showImageList && (
        <ImageList
          onSelect={handleDrop}
          onClose={() => setShowImageList(false)}
        />
      )}
      {showZoom && (
        <GalleryModal
          initialIndex={0}
          photos={[{ location: photo }]}
          onCancel={() => setShowZoom(false)}
        />
      )}
    </section>
  )
}

export default Barbeibot

//@ts-check
import React from 'react'
import { format, formatForExport } from 'utils/math'
import { dateTimeToString } from 'utils/date'
import { getFactura } from 'utils/documents'
import { H3, Paragraph } from 'components/typography'
import Anchor from 'components/anchor/Anchor'
import styles from './Incomes.module.css'

function Incomes({
  incomes = [],
  title = 'Ingresos',
  emptyInfo = 'Este pago no tiene ingresos asociados'
}) {
  return (
    <div>
      <H3>{title}</H3>
      <br />
      {!incomes.length && <Paragraph type='body2'>{emptyInfo}</Paragraph>}
      {incomes
        .sort((a, b) => b.fecha - a.fecha)
        .map(income => {
          return (
            <details key={income.id} className={styles.details}>
              <summary className={styles.summary}>
                <div className={styles.summaryRow}>
                  <Paragraph type='body2Bold' className={styles.summaryTitle}>
                    {income.concepto}
                  </Paragraph>
                  <Paragraph type='body2' className={styles.summaryIncomeData}>
                    {income.formaPago}
                  </Paragraph>
                  <Paragraph type='body2' className={styles.summaryIncomeData}>
                    {dateTimeToString(income.fecha)}
                  </Paragraph>
                  <Paragraph
                    type='body2Bold'
                    className={styles.summaryIncomeData}
                  >
                    {income.centro}
                  </Paragraph>
                  <Paragraph type='body2' className={styles.summaryIncomeData}>
                    {format(income.importe)} €
                  </Paragraph>
                  <Invoice income={income} />
                </div>
              </summary>
            </details>
          )
        })}
    </div>
  )
}

export default Incomes

const Invoice = ({ income }) => {
  if (income.codigoFactura) {
    return (
      <Anchor
        type='secondary'
        href={getFactura({
          academia: income.centro,
          numero_factura: income.codigoFactura,
          cliente: income.nombreFactura,
          nif: income.identificacionFactura,
          referencia: income.id,
          fecha_emision: income.fechaFactura,
          concepto: income.conceptoFactura,
          importe: formatForExport(income.importe)
        })}
      >
        {income.codigoFactura}
      </Anchor>
    )
  }
  return (
    <Paragraph type='body2' className={styles.placeholder}>
      Sin factura
    </Paragraph>
  )
}

import React from 'react'
import { H2 } from 'components/typography'
import Tabs from 'components/tabs/Tabs'
import PaymentTab from './ui/PaymentTab'
import SubscripitonTab from './ui/SubscriptionTab'

function Subscriptions() {
  return (
    <Tabs
      tabs={[
        {
          title: <H2>Tus planes</H2>,
          content: <SubscripitonTab />
        },
        {
          title: <H2>Pagos</H2>,
          content: <PaymentTab />
        }
      ]}
    />
  )
}

export default Subscriptions

import React, { useCallback, useEffect, useState } from 'react'
import { getAttendanceRating } from 'api/rating'
import { useAuth } from 'context/AuthProvider'
import {
  AccessTypes,
  AttendanceStatus,
  RATING_LABELS,
  RATING_SCORES
} from 'utils/constants'
import Tooltip from 'components/tooltips/Tooltip'
import RatingModal from 'components/modals/RatingModal'
import styles from './Rating.module.css'

function RatingButton({ attendance = {}, rating = null, size = 'small' }) {
  const [currentRating, setCurrentRating] = useState(rating)
  const [showRatingModal, setShowRatingModal] = useState(false)
  const { student } = useAuth()

  const isStudent = student.accessType == AccessTypes.STUDENT
  const canBeRated = attendance?.status == AttendanceStatus.VIENE
  const hasRating = !!currentRating?.id
  const isNegative =
    hasRating &&
    [RATING_SCORES.MUY_NEGATIVA, RATING_SCORES.NEGATIVA].includes(
      currentRating.valoracionClase
    )

  const fetchRating = useCallback(() => {
    getAttendanceRating(attendance.id)
      .then(setCurrentRating)
      .catch(e => setCurrentRating(null))
  }, [attendance])

  const handleCloseModal = () => {
    setShowRatingModal(false)
    fetchRating()
  }
  useEffect(() => {
    if (!isStudent || !attendance?.id || rating?.id) return
    fetchRating()
  }, [attendance, fetchRating, isStudent, rating])
  if (!isStudent || !canBeRated) return null

  return (
    <>
      <Tooltip
        classNames={{ title: styles.tooltipTitle }}
        placement='top'
        title={
          <span>
            {hasRating
              ? isNegative
                ? '¡Intentaremos mejorar!'
                : '¡Gracias!'
              : 'Valorar'}
          </span>
        }
      >
        <div
          className={[
            styles.emoji,
            styles[size],
            isNegative ? styles.negative : ''
          ].join(' ')}
          onClick={() => !hasRating && setShowRatingModal(true)}
        >
          {hasRating ? RATING_LABELS[currentRating.valoracionClase] : '🌟'}
        </div>
      </Tooltip>
      {showRatingModal && attendance?.id && (
        <RatingModal
          attendanceId={attendance?.id}
          studentName={student.shortName}
          onClose={handleCloseModal}
        />
      )}
    </>
  )
}

export default RatingButton

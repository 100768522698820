import { GET, PUT, POST } from './index'

export const getClientSecret = () =>
  GET({ url: 'students/billing/client-secret' })

export const listPaymentMethods = () =>
  GET({ url: 'students/billing/payment-methods' })

export const setPaymentMethodFavourite = paymentMethodId =>
  PUT({
    url: 'students/billing/favourite-payment-method',
    data: { paymentMethodId }
  })

export const detachPaymentMethod = paymentMethodId =>
  POST({
    url: 'students/billing/detach-payment-method',
    data: { paymentMethodId }
  })

export const findPayments = () => GET({ url: 'students/billing/payments' })
export const findIncomes = () => GET({ url: 'students/billing/incomes' })

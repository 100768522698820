export const generatePDFURL = documentURL => {
  const encodedURL = encodeURIComponent(documentURL)
  return `https://pdf.mundoestudiante.cloud/?url=${encodedURL}`
}

export const getFactura = ({
  academia,
  numero_factura,
  cliente,
  nif,
  referencia,
  fecha_emision,
  concepto,
  importe
}) => {
  const baseURL = `https://documents.mundoestudiante.cloud/factura`
  const params = new URLSearchParams({
    academia,
    numero_factura,
    cliente,
    nif,
    referencia,
    fecha_emision,
    concepto,
    importe
  })
  return generatePDFURL(`${baseURL}?${params}`)
}

//@ts-check
import { useState } from 'react'
import { useNotificationActions } from 'context/NotificationProvider'
import { createQuestion } from 'api/barbeibot'
import { ENGINES } from 'utils/constants'

function useAgoraBarbeibot() {
  const [state, setState] = useState({
    engine: ENGINES.gpt,
    gpt: { image: null, message: null, isLoading: false },
    claude: { image: null, message: null, isLoading: false },
    gemini: { image: null, message: null, isLoading: false }
  })
  const { setErrorMessage } = useNotificationActions()
  const { engine, gpt, claude, gemini } = state
  const handleSubmit = async question => {
    try {
      setState(prev => ({
        ...prev,
        [engine]: { ...prev[engine], isLoading: true, message: '' }
      }))
      const imageLocation = state[engine].image

      const { respuesta: chunk } = await createQuestion({
        question,
        imageUrl: imageLocation
      })
      setState(prev => ({
        ...prev,
        [engine]: {
          ...prev[engine],
          message: chunk
        }
      }))
    } catch (error) {
      setErrorMessage({})
    } finally {
      setState(prev => ({
        ...prev,
        [engine]: { ...prev[engine], isLoading: false }
      }))
    }
  }

  const handleDrop = image =>
    setState(prev => ({
      ...prev,
      [engine]: { ...prev[engine], image }
    }))

  const handleChangeEngine = engine => setState(prev => ({ ...prev, engine }))

  return {
    engine,
    gpt,
    claude,
    gemini,
    handleChangeEngine,
    handleDrop,
    handleSubmit
  }
}

export default useAgoraBarbeibot

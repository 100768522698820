import React from 'react'
import ChevronLeft from 'assets/icons/ChevronLeft'
import ChevronRight from 'assets/icons/ChevronRight'
import {
  getMonthDaysInfo,
  dateToString,
  isSameDay,
  isPast,
  formatDate
} from 'utils/date'
import { noop } from 'utils'
import DotsSpinner from 'components/spinners/DotsSpinner'
import { Paragraph } from 'components/typography'
import styles from './SimpleIndicatorCalendar.module.css'

function SimpleIndicatorCalendar({
  selectedDate = new Date(),
  minDate = new Date(),
  data = {},
  disabled = false,
  disabledPast = false,
  isLoading = false,
  onChangeDate = noop
}) {
  const selectedMonth = selectedDate.getMonth()
  const selectedYear = selectedDate.getFullYear()

  const handleChangeDate = (date = new Date()) => {
    if (disabled) return
    onChangeDate(date)
  }
  const handleChangeMonth = (amount = 0) => {
    if (disabled) return
    let newYear = selectedYear
    let newMonth = selectedMonth + amount
    if (newMonth === 12) {
      newMonth = 0
      newYear++
    } else if (newMonth === -1) {
      newMonth = 11
      newYear--
    }

    onChangeDate(new Date(newYear, newMonth, 1, 0, 0, 0, 0))
  }
  const weeks = getMonthDaysInfo(selectedYear, selectedMonth)
  return (
    <>
      <Actions
        title={formatDate({
          date: selectedDate,
          stringFormat: 'MMMM, Y'
        })}
        disabled={disabled}
        isLoading={isLoading}
        onChange={handleChangeMonth}
      />

      <div className={styles.calendar}>
        <div className={styles.header}>
          {['L', 'M', 'X', 'J', 'V', 'S', 'D'].map(day => (
            <div key={day} className={styles.dayHeader}>
              {day}
            </div>
          ))}
        </div>

        <div className={styles.grid}>
          {weeks.map((week, weekIndex) => (
            <div key={weekIndex} className={styles.week}>
              {week.map((date, dayIndex) => {
                const formattedDate = dateToString(date)
                const hasData = data?.[formattedDate] > 0
                const currentMonth = date.getMonth()
                const isToday = isSameDay(new Date(), date)
                const isSelectedDate = isSameDay(selectedDate, date)
                const isDayPast = disabledPast ? isPast(date, minDate) : false
                return (
                  <div
                    key={dayIndex}
                    className={[
                      styles.day,
                      isToday ? styles.today : '',
                      currentMonth !== selectedDate.getMonth()
                        ? styles.dayOutOfRange
                        : '',
                      isSelectedDate ? styles.selectedDate : '',
                      isDayPast ? styles.dayDisabled : ''
                    ].join(' ')}
                    onClick={() => handleChangeDate(date)}
                  >
                    <span>{date.getDate()}</span>
                    {hasData && <div className={styles.dot} />}
                  </div>
                )
              })}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default SimpleIndicatorCalendar

function Actions({ title, isLoading, disabled, onChange }) {
  return (
    <div className={styles.actions}>
      <ChevronLeft
        className={[
          styles.chevron,
          disabled ? styles.chevronDisabled : ''
        ].join(' ')}
        onClick={() => onChange(-1)}
      />
      {isLoading ? (
        <DotsSpinner />
      ) : (
        <div className={styles.actions}>
          <Paragraph className={styles.title} type='body2Bold'>
            {title}
          </Paragraph>
        </div>
      )}
      <ChevronRight
        className={[
          styles.chevron,
          disabled ? styles.chevronDisabled : ''
        ].join(' ')}
        onClick={() => onChange(1)}
      />
    </div>
  )
}

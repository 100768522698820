import React, { useState } from 'react'
import Cross from 'assets/icons/Cross'
import { H3, Paragraph } from 'components/typography'
import styles from './Alert.module.css'

function Alert({ type = 'primary', title, message, showCloseIcon = true }) {
  const [show, setShow] = useState(true)
  if (!show) return null
  return (
    <div className={`${styles.alertContainer} ${styles[type]}`}>
      <div className={styles.alertContent}>
        <H3 className={styles.alertTitle}>{title}</H3>
        <Paragraph className={styles.alertMessage}>{message}</Paragraph>
      </div>
      {showCloseIcon && (
        <div className={styles.closeIcon} onClick={() => setShow(false)}>
          <Cross id={type} color='var(--bright)' />
        </div>
      )}
    </div>
  )
}

export default Alert
